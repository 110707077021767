@tailwind base;
@tailwind components;
@tailwind utilities;

.z-index0-force {
  z-index: 0 !important;
}

.Modal {
  top: "50%";
  left: "50%";
  right: "auto";
  bottom: "auto";
  margin-right: "-50%";
  transform: "translate(-50%, -50%)";
}
